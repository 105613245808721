<script setup lang="ts">
import { computed, nextTick, onActivated, ref, watch } from 'vue'
import { useTranslator } from 'sima-suite-translator-vue3'
import { useAppointmentStore } from '@/store'
import dayjs from '@/utils/dayjs.ts'
import {
  isMobile,
  ListItem,
  SAlertType,
  SCustomList,
  SIconButton,
  SListItem,
  SMainToolbar,
  SProgressCircular
} from 'sima-suite-components-vue3'
import { useDate } from 'vuetify'
import { createARelevantMessage } from '@/utils'
import NoAvailabilityMessage from '@/components/molecules/NoAvailabilityMessage/NoAvailabilityMessage.vue'

const MAX_AVAILABILITY_CALLS = 2
const { translate, locale } = useTranslator()
const vuetifyDateComposable = useDate()
const selectedDate = ref(new Date())
const month = ref(selectedDate.value.getMonth())
const year = ref(selectedDate.value.getFullYear())
const appointmentStore = useAppointmentStore()
const allowedDates = computed(() => {
  if (appointmentStore.availableDays.length > 0) {
    return appointmentStore.availableDays
  }

  return () => false
})
const availableHours = ref<number[]>([])
const hours = computed<ListItem[]>(() => {
  return availableHours.value.map((hour) => {
    return { primaryText: `${hour}:00 - ${hour + 1}:00`, range: hour }
  })
})
const rangeSelected = ref(NaN)
const isLoading = ref(false)
const workshopPhone = computed(() => appointmentStore.workshopSelected.phone)
const showCalendar = ref(true)
const isFirstLoad = ref(true)

const transformDate = (date: Date) => {
  const year = vuetifyDateComposable.format(date, 'year')
  const month = vuetifyDateComposable.getMonth(date) + 1
  const day = vuetifyDateComposable.format(date, 'dayOfMonth')
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}

const getAvailability = async (date: Date) => {
  isLoading.value = true
  try {
    await appointmentStore.getWorkshopAvailability(date)
    if (
      appointmentStore.availableDays.length === 0 &&
      appointmentStore.availabilityCalls < MAX_AVAILABILITY_CALLS
    ) {
      let nextMonth = date.getMonth()
      let nextYear = date.getFullYear()
      if (nextMonth == 11) {
        nextMonth = 0
        nextYear = nextYear + 1
      } else {
        nextMonth = nextMonth + 1
      }
      const dateAsString = `${nextYear}-${(nextMonth + 1).toString().padStart(2, '0')}-01`
      const dateISO : any = vuetifyDateComposable.parseISO(dateAsString)
      const newDate = new Date(dateISO.toISOString())
      selectedDate.value = newDate
      await appointmentStore.getWorkshopAvailability(selectedDate.value)
    }
  } catch (error) {
    createARelevantMessage(translate('Error.Step.Appointment'), SAlertType.ERROR)
  } finally {
    if (
      typeof allowedDates.value !== 'function' &&
      allowedDates.value &&
      allowedDates.value.length > 0
    ) {
      const allowedDateISO : any = vuetifyDateComposable.parseISO(allowedDates.value[0])
      selectedDate.value = new Date(allowedDateISO.toISOString())
    }
    isLoading.value = false
  }
}

onActivated(async () => {
  appointmentStore.resetAvailability()
  isFirstLoad.value = true
  const date =
    selectedDate.value.getMonth() > new Date().getMonth()
      ? new Date(dayjs(selectedDate.value).startOf('month').utc(true).toISOString())
      : new Date(dayjs().utc().toISOString())

  await getAvailability(date)
  isFirstLoad.value = false
})

const handleSlotTime = (timeOption: ListItem) => {
  rangeSelected.value = timeOption.range as unknown as number
  appointmentStore.workshopAvailabilityAdviser = undefined
  appointmentStore.workshopAvailabilityRange = timeOption.range as unknown as number
}

const goToScheduleRange = () => {
  showCalendar.value = false
}

const backToCalendar = () => {
  showCalendar.value = true
}

const showSlotList = computed(() => {
  return (
    (isMobile.value && !showCalendar.value && appointmentStore.availableDays.length) ||
    (!isMobile.value && appointmentStore.availableDays.length)
  )
})

watch(
  () => selectedDate.value,
  (newValue) => {
    if (!isMobile.value) {
      appointmentStore.workshopAvailabilityDate = transformDate(newValue)
      availableHours.value = appointmentStore.availableRangeHours
      appointmentStore.workshopAvailabilityRange = NaN
      rangeSelected.value = NaN
      appointmentStore.workshopAvailabilityAdviser = undefined
      if (appointmentStore.availableDays.length > 0 && !isFirstLoad.value) {
        showCalendar.value = false
      }
    }
  }
)

const handleSelectDate = (currentDate: Date) => {
  appointmentStore.workshopAvailabilityDate = transformDate(currentDate)
  availableHours.value = appointmentStore.availableRangeHours
  appointmentStore.workshopAvailabilityRange = NaN
  rangeSelected.value = NaN
  appointmentStore.workshopAvailabilityAdviser = undefined
  showCalendar.value = false
}

watch(
  () => month.value,
  async () => {
    if (month.value >= 0 && month.value <= 11) {
      const dateAsString = `${year.value}-${(month.value + 1).toString().padStart(2, '0')}-01`
      const dateISO : any = vuetifyDateComposable.parseISO(dateAsString)
      const date = new Date(dateISO.toISOString())
      const currentDate = new Date()
      selectedDate.value = dayjs(date).isSameOrBefore(dayjs(currentDate))
        ? currentDate
        : date
      month.value = selectedDate.value.getMonth()
      year.value = selectedDate.value.getFullYear()
      await getAvailability(selectedDate.value)
    }
  }
)

watch(
  () => year.value,
  async () => {
    const monthValue = month.value
    month.value = -1
    await nextTick()
    month.value = monthValue
  }
)
</script>

<template>
  <div
    v-if="!isLoading"
    class="flex h-full w-full overflow-y-hidden sm:gap-spacing-m sm:p-spacing-s"
  >
    <no-availability-message
      v-if="isMobile && appointmentStore.availableDays.length === 0"
      :workshop-phone="workshopPhone ? workshopPhone : ''"
    />
    <div
      v-else-if="!isMobile || showCalendar"
      class="flex h-full w-full flex-grow-0 flex-col items-end sm:w-3/5"
    >
      <s-icon-button
        v-if="isMobile"
        class="m-[0.5rem] md:hidden"
        icon="mdi-arrow-right"
        :title="translate('AppointmentWizard.Steps.Step4.Button.GoToScheduleRange')"
        @icon-button-click="goToScheduleRange"
      />
      <div class="h-full w-full overflow-y-scroll">
        <v-date-picker
          v-model:model-value="selectedDate"
          v-model:month="month"
          v-model:year="year"
          color="primary"
          width="100%"
          bg-color="transparent"
          :min="new Date()"
          :allowed-dates="allowedDates"
          :border="!isMobile"
          rounded="xl"
          :title="translate('AppointmentWizard.Steps.Step4.Calendar.Title')"
          @update:model-value="handleSelectDate"
        />
      </div>
    </div>
    <div v-if="showSlotList" class="w-full overflow-y-hidden sm:w-2/5">
      <s-icon-button
        v-if="isMobile"
        class="m-spacing-xs md:hidden"
        icon="mdi-arrow-left"
        :title="translate('AppointmentWizard.Steps.Step4.Button.GoToCalendar')"
        @icon-button-click="backToCalendar"
      />
      <s-main-toolbar
        class="pb-spacing-s"
        :title="
          translate('AppointmentWizard.Steps.Step4.ChooseHourRange', [
            selectedDate.toLocaleString(locale, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour12: false
            })
          ])
        "
      />
      <div class="h-[calc(100%_-_6rem)] overflow-y-auto pb-spacing-xl">
        <s-custom-list :items="hours">
          <template #default>
            <template v-for="hour in hours" :key="hour.range">
              <s-list-item
                :primary-text="hour.primaryText"
                :class="{ 'bg-color-neutral-150': hour.range === rangeSelected }"
                @item-click="handleSlotTime(hour)"
              />
            </template>
          </template>
        </s-custom-list>
      </div>
    </div>
    <no-availability-message
      v-else-if="!isMobile"
      :workshop-phone="workshopPhone ? workshopPhone : ''"
    />
  </div>
  <div v-else class="flex size-full items-center justify-center">
    <s-progress-circular />
  </div>
</template>
